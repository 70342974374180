import React from 'react';
import logo from '../assets/logo.png'
function Header() {
    return (<>

        <header className="bg-white site-header ">
            <div className="">
                <div className="site-header-inner">
                    <div className="brand header-brand align-content-lg-start">

                        <ul className="nav ">
                            <li className={'nav justify-content-start'}>
                                <a href={'/'}>
                                    <img  src={logo} width={80} height={80}></img>
                                </a></li>



                        </ul>
                    </div>
                </div>
            </div>
        </header>

    </>);
}

export default Header;
